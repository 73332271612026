<template>
    <div class="list">
        <RequestCard
            v-for="(request, index) in dataList"
            :key="index"
            :item="request"
            @handleShowDetail="handleShowDetail"
        />
        <div class="list__pagination">
          <b-pagination
            v-if="hasItems"
            v-model="pagination.currentPage"
            :per-page="pagination.itemsPerPage"
            :total-rows="pagination.totalItems"
            @change="handleSearch"
            pills
            align="fill"
          />
        </div>
        <Detail
          v-if="showDetails"
          :request="currentRequest"
          @handleClose="handleClose"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getOrdenation } from '@/helpers/ApiHelper';
import { getFormattedDate } from '@/helpers/ComponentHelper';

export default {
  mounted() {
    this.handleSearch();
  },
  props: {},
  data() {
    return {
      // showDetails: false,
      currentRequest: {},
      dataList: [],
      sort: {
        name: 'status',
        type: 'desc',
      },
    };
  },
  watch: {
    requestList(newValue) {
      this.mapToCard(newValue);
    },
  },
  methods: {
    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('request/filterRequest', this.getRequestData(page))
        .then(() => {
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    getRequestData(page = 1) {
      return {
        page,
        perPage: 10,
        ordenation: getOrdenation(this.sort),
      };
    },
    paginate(currentPage) {
      this.handleSearch(currentPage);
    },
    mapToCard(newList) {
      const list = [];
      newList.forEach((currentValue) => {
        list.push({
          patientName: `${currentValue.patient.name} ${currentValue.patient.lastname}`,
          specialty: currentValue.specialty.name,
          date: getFormattedDate(currentValue.createdAt),
          status: currentValue.status.toLowerCase(),
          reqId: currentValue.id,
        });
      });
      this.dataList = list;
    },
    handleShowDetail(reqId) {
      this.currentRequest = this.requestList.find((item) => item.id === reqId);
      this.showDetails = true;
    },
    handleClose() {
      this.showDetails = false;
    },
  },
  computed: {
    ...mapGetters({
      requestList: 'request/getRequestListState',
      pagination: 'request/getRequestPaginationState',
    }),
    showDetails: {
      get() { return this.$store.state.ui.showMobileDetails; },
      set(value) { this.$store.commit('ui/setShowMobileDetails', value); },
    },
    hasItems() {
      return this.requestList && this.requestList.length > 0;
    },
  },
  components: {
    RequestCard: () => import('@/components/atoms/Cards/RequestCard/RequestCard.vue'),
    Detail: () => import('@/components/organisms/Request/mobileTabs/detail/Detail.vue'),
  },
};
</script>
<style lang="sass" scoped>
  @import './mobileTabs.sass'
</style>
